import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthorizationService {
  showDashboardTab = new ReplaySubject<boolean>(1);
  showEquipmentTab = new ReplaySubject<boolean>(1);
  showOpenTicketsTab = new ReplaySubject<boolean>(1);
  showPlannedActivityTab = new ReplaySubject<boolean>(1);
  showPlannedAppointmentsTab = new ReplaySubject<boolean>(1);
  showPSRTab = new ReplaySubject<boolean>(1);
  showPSRandRWF = new ReplaySubject<boolean>(1);
  showPSRandWeScan = new ReplaySubject<boolean>(1);
  showContractsTab = new ReplaySubject<boolean>(1);
  showCustAdminTab = new ReplaySubject<boolean>(1);
  showImpersonationTab = new ReplaySubject<boolean>(1);
  showPartnerOrderTab = new ReplaySubject<boolean>(1);
  showInvoicesTab = new ReplaySubject<boolean>(1);
  showEquipmentInvoicesTab = new ReplaySubject<boolean>(1);
  showSystemUpdatesTab = new ReplaySubject<boolean>(1);
  showSecurityTab = new ReplaySubject<boolean>(1);
  showSprTab = new ReplaySubject<boolean>(1);
  showAdvisoriesTab = new ReplaySubject<boolean>(1);
  showDigitalCompassTab = new ReplaySubject<boolean>(1);
  showReportingTab = new ReplaySubject<boolean>(1);
  createTicketRole$ = new ReplaySubject<boolean>(1);
  equipmentDocumentOverviewComponent = new ReplaySubject<boolean>(1);
  showTeamplayWidget = new ReplaySubject<boolean>(1);
  showEquipmentSecurityTab = new ReplaySubject<boolean>(1);
  showEquipmentCheckSheetTab = new ReplaySubject<boolean>(1);
  showMarketingConsentTrigger = new ReplaySubject<boolean>(1);
  showUpdateSubprocessorHQ = new ReplaySubject<boolean>(1);
  showCybersecurityOverview = new ReplaySubject<boolean>(1);
}
